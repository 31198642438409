<template>
  <div class="wrap">
    <el-container>
      <el-header height="48px">
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside width="208px" id="el_aside">
          <div class="logo">
            颐柏招募
          </div>
          <el-menu
            class="el-menu-vertical-demo"
            :default-openeds="openList"
            :default-active="$route.path"
            router
            @open="handleOpen"
            @close="handleClose"
          >
            <el-submenu index="2">
              <template slot="title">
                <span class="events">招募管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/">招募列表</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="/members">报名人员</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="/channel">渠道列表</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="/city">招募城市</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span class="membership">内容管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="swiper">轮播设置</el-menu-item>
              </el-menu-item-group>
              <!-- <el-menu-item-group>
                <el-menu-item index="message">自动回复</el-menu-item>
              </el-menu-item-group> -->
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main id="el_main">
          <Login></Login>
          <router-view></router-view>
          <a target="_blank" style="display:block; position:  sticky; bottom: 0; height: 20px;  width: 100%; margin-top: 20px; text-align:  center; color: #999; font-size: 12px;" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">浙ICP备2023021258号</a>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<style scoped>
 .el-main{
   padding-top: 0;
 }
</style>
<script>
import Header from '@/components/Header.vue';
import Login from '@/components/Login.vue';

export default {
  components: {
    Header,
    Login,
  },
  data() {
    return {
      openList: ['1', '2', '3', '4'],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang='less'>
  .wrap {
    min-height: 100vh;
  }
  #el_aside {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: 2px 0px 6px 0px rgba(220,220,220,0.45);
    background: #fff;
    z-index: 9;
  }
  .el-header {
    padding-right: 0;
  }
  .el-menu {
    border: none;
  }
  .logo {
    width: 208px;
    height: 68px;
    text-align: center;
    line-height: 68px;
    color: #008751;
    font-weight: bold;
    font-size: 28px;
  }
  .is-active .el-submenu__title {
    font-weight: bold;
    color: #008751;
  }
  .el-submenu__title {
    height: 40px;
    padding-left: 16px !important;
    margin-bottom: 8px;
    line-height: 40px;
    span {
      padding-left: 24px;
    }
    .events {
      background: url(../assets/icon_event.png) 0 center no-repeat;
      background-size: 14px auto;
    }
    .membership {
      background: url(../assets/icon_account.png) 0 center no-repeat;
      background-size: 14px auto;
    }
    .message {
      background: url(../assets/icon_message.png)  0 center no-repeat;
      background-size: 14px auto;
    }
  }
  .is-active {
    .events {
      background: url(../assets/icon_event-active.png) 0 center no-repeat;
      background-size: 14px auto;
    }
    .membership {
      background: url(../assets/icon_account-active.png) 0 center no-repeat;
      background-size: 14px auto;
    }
    .message {
      background: url(../assets/icon_message.png)  0 center no-repeat;
      background-size: 14px auto;
    }
  }
  .el-menu-item-group__title {
    display: none;
  }
  .el-submenu .el-menu-item {
      height: 40px;
      margin-bottom: 8px;
      line-height: 40px;
      padding: 0 42px;
  }
  .el-menu-item.is-active {
    font-weight: bold;
    background: rgba(37, 211, 147, .15);
    border-right: 2px solid #008751;
  }
  .el-table .cell {
    display: -webkit-box;
  /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
