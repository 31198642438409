<template>
  <div id="tag-manage-container">
    <div class="ht-top">
      招募管理 <span>/ 参与人员</span>
      <div class="title">
        参与人员
      </div>
      <div class="operation">
        <el-button type="primary" @click="exportAllData">导出所有数据</el-button>
      </div>
    </div>
    <div class="search-container">
      <el-form ref="form" :inline="true" label-width="112px" align="left" size="mini">
        <el-form-item label="项目ID">
          <el-input
              placeholder="请输入项目ID"
              v-model="pid"
              clearable>
            </el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-autocomplete
            v-model="name"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
      </el-form>
      <div class="ht-button">
        <el-button @click="onReset">重置</el-button>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </div>
    </div>
    <div class="list-container">
      <div class="ht-middle">
        <h2 class="ht-title">{{name || '人员列表'}}</h2>
        <div class="operation">
          <el-button class="big" type="primary" @click="exportData" width="140px">导出数据</el-button>
          <el-button type="primary" plain @click="batchPass">批量通过</el-button>
          <el-button type="primary" plain @click="batchReject">批量拒绝</el-button>
          <el-button type="primary" plain @click="batchReset">批量还原</el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        empty-text="没有相关数据"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          fixed
          class-name="selection"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          min-width="80">
        </el-table-column>
        <el-table-column
          prop="progress"
          label="状态"
          sortable
          min-width="80"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.progress == 1">待审核</el-tag>
            <el-tag type="success" v-if="scope.row.progress == 2">已通过</el-tag>
            <el-tag type="danger" v-if="scope.row.progress == 3">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          sortable
          width="80"
        >
          <template slot-scope="scope">
            {{ scope.row.sex == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系方式"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="idCard"
          min-width="200"
          label="身份证号">
        </el-table-column>
        <el-table-column
          prop="age"
          width="80"
          sortable
          label="年龄">
        </el-table-column>
        <el-table-column
          prop="bmi"
          width="100"
          label="身高/体重"
        >
          <template slot-scope="scope">
              {{ scope.row.high }} /  {{ scope.row.weight }}
          </template>
        </el-table-column>
        <el-table-column
          prop="somke"
          min-width="80"
          label="是否吸烟"
        >
          <template slot-scope="scope">
              {{ scope.row.smoke == 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          min-width="250"
          label="地址">
        </el-table-column>
        <el-table-column
          prop="remarks"
          min-width="250"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="hasIdCard"
          min-width="100"
          label="提供身份证"
        >
          <template slot-scope="scope">
              {{ scope.row.hasIdCard == 1 ? '能' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="hasReport"
          min-width="80"
          label="提供材料"
        >
          <template slot-scope="scope">
              {{ scope.row.hasReport == 1 ? '能' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="signStatus"
          label="Actions"
          width="140"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button @click="view(scope.row)" type="text">查看</el-button>
            <el-button v-if="scope.row.progress != 2" type="text" @click="update(scope.row, 2)">通过</el-button>
            <el-button  v-if="scope.row.progress != 3" type="text" @click="update(scope.row, 3)">拒绝</el-button>
            <el-button v-if="scope.row.progress != 1" type="text" @click="update(scope.row, 1)">还原</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div id="pagination">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next, jumper"
          @current-change="getData"
          :current-page.sync="page"
          :page-size="20"
          :total.sync="total">
        </el-pagination>
      </div>
    </div>

    <UserInfoDialog :visible.sync="userInfoDialogVisible" :userInfo="userInfo"></UserInfoDialog>

    <!-- <el-dialog
      title="消息配置"
      :visible.sync="messageDialog"
      class="mem-dialog"
      width="420px">
      <el-form ref="form" label-position="top">
        <el-form-item label="事件">
          <el-input v-model="data1" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item  label="时间">
          <el-date-picker
              v-model="time1"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="请选择">
            </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="tagDialogVisible = false">Cancel</el-button>
        <el-button :loading="commitTagLoading" type="primary" size="small" @click="handleBatchEditTag">Save</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<style lang="less">
</style>
<style scoped lang="less">
  .big {
    width: 140px;
  }
  .list-container .el-tag--light {
    margin-bottom: 5px;
    margin-right: 5px;
  }
  #pagination{
    margin-top: 30px;
  }
  .show-item{
    margin-bottom: 15px;
    font-size: 16px;
    .label{
      display: inline-block;
      width: 80px;
      font-weight: bold;
    }
    img.value {
      display: block;
      width: 400px;
      margin-top: 15px;
      max-height: 300px;
    }
  }
  .tag-tips {
    margin: 20px 0;
  }
  .tip {
    font-size: 12px;
    p {
      margin-bottom: 5px;
    }
  }
  .upload-dialog  /deep/ .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }
.upload-dialog  /deep/ .file-uploader .el-upload {
  display: block;
  height: 32px;
  padding: 0px 10px;
  margin-left: 12px;
  line-height: 32px;
  background: #339f74;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}
</style>
<script>
import http from '@/lib/http';
import UserInfoDialog from '@/components/UserInfoDialog.vue';
import getUrlParam from '@/utils/getUrlParam';

export default {
  name: 'AttendList',
  components: {
    UserInfoDialog,
  },
  data() {
    return {
      pid: '',
      page: 1,
      name: '',
      total: 0,
      keyword: '',
      messageDialog: false,
      lastName: '',
      firstName: '',
      userMobile: '',
      selectedRow: {},
      tableData: [],
      multipleSelection: [],
      userInfo: {},
      userInfoDialogVisible: false,
    };
  },
  created() {
    this.pid = +getUrlParam('pid') || '';
    this.name = getUrlParam('projectName') || '';
    if (this.pid) {
      this.getData();
    }
  },
  methods: {
    onSearch() {
      this.getData();
    },
    onReset() {
      this.page = 1;
      this.name = '';
      this.pid = '';
      this.total = 0;
      this.tableData = [];
    },
    querySearchAsync(queryString, cb) {
      http.post('/project/getProject', {
        pageSize: 5,
        pageNum: 1,
        city: '',
        filterStr: queryString,
      }).then((res) => {
        const data = res.data || {};
        if (data.code === 200) {
          const list = data?.data?.dataList || [];
          const suggestions = [];
          // eslint-disable-next-line array-callback-return
          list.forEach((item, index) => {
            suggestions[index] = {};
            suggestions[index].value = item.projectName;
            suggestions[index].id = item.id;
          });
          cb(suggestions);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    handleSelect(item) {
      this.pid = item.id;
      this.onSearch();
    },
    getData() {
      http.post('userCenter/getPartInUser', {
        pageSize: 20,
        id: this.pid,
        pageNum: this.page,
      }).then((res) => {
        const { data, code, message } = res.data;
        if (code === 200) {
          if (data) {
            this.total = data.total || 0;
            this.tableData = data?.dataList || [];
          } else {
            this.total = 0;
            this.tableData = [];
          }
          this.multipleSelection = [];
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    view(row) {
      this.userInfo = row;
      this.userInfo.reportFiles = this.userInfo.reportFile ? this.userInfo.reportFile.split(',') : [];
      this.userInfoDialogVisible = true;
    },
    update(row, progress) {
      // eslint-disable-next-line no-nested-ternary
      const content = progress === 1 ? '确认恢复为待审核？' : progress === 2 ? '确认通过该审核？' : '确认拒绝该审核';
      const ids = [row.id];
      const userIds = [row.userid];
      const names = [row.contacts];
      this.$confirm(content, '提示', {
      }).then(() => {
        this.updateVerifyStatus(ids, userIds, names, progress);
      }).catch(() => {
        console.log('cancel');
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    batchPass() {
      if (!this.multipleSelection.length) {
        return;
      }
      this.$confirm('确认通过这些审核', '提示', {
      }).then(() => {
        this.updateVerifyStatus(this.multipleSelection.map(item => item.id), this.multipleSelection.map(item => item.userid), this.multipleSelection.map(item => item.contacts), 2);
      }).catch(() => {
        console.log('cancel');
      });
    },
    batchReject() {
      if (!this.multipleSelection.length) {
        return;
      }
      this.$confirm('确认拒绝这些审核', '提示', {
      }).then(() => {
        this.updateVerifyStatus(this.multipleSelection.map(item => item.id), this.multipleSelection.map(item => item.userid), this.multipleSelection.map(item => item.contacts), 3);
      }).catch(() => {
        console.log('cancel');
      });
    },
    batchReset() {
      if (!this.multipleSelection.length) {
        return;
      }
      this.$confirm('确认恢复这些审核', '提示', {
      }).then(() => {
        this.updateVerifyStatus(this.multipleSelection.map(item => item.id), this.multipleSelection.map(item => item.userid), this.multipleSelection.map(item => item.contacts), 1);
      }).catch(() => {
        console.log('cancel');
      });
    },
    exportData() {
      http.get(`/userCenter/exportPartInUser?pId=${this.pid}`).then((res) => {
        const { data, code, message } = res.data;
        console.log(res, data);
        if (code === 200) {
          window.location.href = data;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    exportAllData() {
      http.get('/userCenter/exportPartInUser?pId=').then((res) => {
        const { data, code, message } = res.data;
        console.log(res, data);
        if (code === 200) {
          window.location.href = data;
        } else {
          this.$message({
            message,
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
    updateVerifyStatus(ids, userIds, names, progress) {
      const params = {
        ids,
        progress,
      };

      if (progress !== 1) {
        const data = {};
        userIds.map((v, i) => {
          data[v] = {
            thing10: {
              value: this.name.length < 20 ? this.name : `${this.name.substring(0, 16)}...`,
              color: '#459ae9',
            },
            thing2: {
              value: progress === 2 ? '审核通过' : '审核失败',
              color: '#459ae9',
            },
            phone_number11: {
              value: names[i],
              color: '#459ae9',
            },
          };
          return null;
        });
        params.userIds = userIds;
        params.templateId = '2FhCb8nunIP_-QhJfDuyIPeqOJcLb6aaN5ia00TmHP8';
        params.data = JSON.stringify(data);
      }
      http.post('/userCenter/checkPartInUser', params).then((res) => {
        const data = res.data || {};
        if (data.code === 200) {
          this.getData();
        } else {
          this.$message({
            message: data.message || '操作失败',
            type: 'error',
          });
        }
      }).catch((err) => {
        this.$message({
          message: err || 'Network error',
          type: 'error',
        });
      });
    },
  },
};
</script>
<style lang="less">
.question-item {
  margin-bottom: 15px;
  font-size: 16px;
  .label {
    display: block;
    font-weight: bold;
  }
  .value {
    display: block;
  }
}
.ht-middle .operation {
  position: relative;
  display: flex;
  margin-left: 30px;
}
</style>
